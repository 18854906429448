<template>
  <div>
    <v-navigation-drawer v-model="drawer" width="300" app>
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="this.photourl" height="100" width="100" contain></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>aejMIS</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <div>
        <v-list v-if="isAdmin">
          <v-list-item
            v-for="item in adminMenu"
            :key="item.title"
            :to="item.link"
          >
            <v-layout v-if="item.heading" :key="item.heading" row align-center>
              <v-flex xs6>
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-flex>
            </v-layout>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-if="!isAdmin">
          <v-list-item
            v-for="item in guestMenu"
            :key="item.title"
            :to="item.link"
          >
            <v-layout v-if="item.heading" :key="item.heading" row align-center>
              <v-flex xs6>
                <v-subheader v-if="item.heading">
                  {{ item.heading }}
                </v-subheader>
              </v-flex>
            </v-layout>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title style="width: 250px" class="ml-0 pl-4">
        <router-link class="white--text hidden-xs-only" to="/dashboard">{{
          this.currentUser
        }}</router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn text @click="logout">
        <v-icon class="mr-2">mdi-power</v-icon>
        Logout
      </v-btn>
    </v-app-bar>
    <v-main>
      <slot />
    </v-main>
  </div>
</template>

<script>
import { auth } from "../services/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";

export default {
  data() {
    return {
      uid: "",
      isAdmin: false,
      currentUser: "",
      photourl: "",
      drawer: true,
      adminMenu: [
        {
          title: "Dashboard",
          icon: "mdi-arrow-right-bold-outline",
          link: "/dashboard",
        },
        { heading: "DOCUMENTS" },
        {
          title: "Invoices",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/invoices",
        },
        {
          title: "Receipts",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/receipts",
        },
        {
          title: "Credit Notes",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/creditnotes",
        },
        {
          title: "e-Tickets",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/tickets",
        },
        {
          title: "No Dues Certificate",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/nodues",
        },
        { heading: "REPORTS" },
        {
          title: "Statement of Accounts",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/outstandings",
        },
        {
          title: "Basic Sales Report",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/adminbasicsales",
        },
        {
          title: "Detailed Sales Report",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/admindetailedsales",
        },
        {
          title: "Sales Register",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/adminsalesreg",
        },    
        {
          title: "Credit Notes Register",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/admincrnotesreg",
        },             
        {
          title: "Airlines Ranking",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/admincarriers",
        },
        {
          title: "Airport/City Pairs Ranking",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/admincitypairs",
        },
        {
          title: "Destinations Ranking",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/admindestinations",
        },
        { heading: "ADMINISTRATION" },

        {
          title: "Customer List",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/customers",
        },

        {
          title: "Airlines List",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/airlines",
        },

        {
          title: "Airports List",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/airports",
        },
        {
          title: "Cabin Classes",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/cabins",
        },

        { heading: "SYSTEM" },
        {
          title: "User Management",
          icon: "mdi-arrow-right-bold-outline",
          link: "/admin/users",
        },
      ],

      guestMenu: [
        {
          title: "Dashboard",
          icon: "mdi-arrow-right-bold-outline",
          link: "/dashboard",
        },
        { heading: "DOCUMENTS" },
        {
          title: "Invoices",
          icon: "mdi-arrow-right-bold-outline",
          link: "/client/invoices",
        },
        {
          title: "Receipts",
          icon: "mdi-arrow-right-bold-outline",
          link: "/client/receipts",
        },
        {
          title: "Credit Notes",
          icon: "mdi-arrow-right-bold-outline",
          link: "/client/creditnotes",
        },
        {
          title: "e-Tickets",
          icon: "mdi-arrow-right-bold-outline",
          link: "/client/tickets",
        },
        {
          title: "No Dues Certificate",
          icon: "mdi-arrow-right-bold-outline",
          link: "/client/nodues",
        },
        { heading: "REPORTS" },
        {
          title: "Statement of Accounts",
          icon: "mdi-arrow-right-bold-outline",
          link: "/client/outstandings",
        },
        {
          title: "Basic Sales Report",
          icon: "mdi-arrow-right-bold-outline",
          link: "/client/basicsales",
        },
        {
          title: "Detailed Sales Report",
          icon: "mdi-arrow-right-bold-outline",
          link: "/client/detailedsales",
        },
        {
          title: "Sales Register",
          icon: "mdi-arrow-right-bold-outline",
          link: "/client/salesreg",
        }, 
        {
          title: "Credit Notes Register",
          icon: "mdi-arrow-right-bold-outline",
          link: "/client/crnotesreg",
        },                
        {
          title: "Airline Ranking",
          icon: "mdi-arrow-right-bold-outline",
          link: "/client/carriers",
        },
        {
          title: "Airport/City Pairs Ranking",
          icon: "mdi-arrow-right-bold-outline",
          link: "/client/citypairs",
        },
        {
          title: "Destination Ranking",
          icon: "mdi-arrow-right-bold-outline",
          link: "/client/destinations",
        },
      ],
    };
  },

  created() {
    onAuthStateChanged(auth, (userAuth) => {
      if (userAuth) {
        this.currentUser = userAuth.displayName;
        if (userAuth.photoURL != null) {
          this.photourl = userAuth.photoURL;
        } else {
          this.photourl = "img/aej-logo.png";
        }
        auth.currentUser.getIdTokenResult().then((tokenResult) => {
          this.isAdmin = tokenResult.claims.admin;
        });
      }
    });
  },

  methods: {
    async logout() {
      await signOut(auth);
      await this.$store.dispatch("setInvoices", null);
      this.$store.dispatch("setUser", "");
      localStorage.removeItem("TOTP_USER");
      localStorage.removeItem("TOTP_TOKEN");
      localStorage.removeItem("LEDG_KEY");
      localStorage.removeItem("USER_NAME");
      localStorage.removeItem("ADDRESS_1");
      localStorage.removeItem("ADDRESS_2");
      localStorage.removeItem("ADDRESS_3");
      localStorage.removeItem("PIN_CODE");
      localStorage.removeItem("PERMITTED");

      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none !important;
}
.v-btn {
  font-size: 1em;
}
</style>
